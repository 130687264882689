/**
 * @file
 */

(function ($) {
  $(document).ready(function () {
    var cboxOptions = {
      width: "100%",
      height: "100%",
      maxWidth: "853px",
      maxHeight: "480px",
      iframe: true,
      scrolling: false,
      closeButton: true
    }

    $("body").on("click touchstart", ".Video__play", function (event) {
        event.preventDefault();
        var videoUrl = $(this).data("url") + '?autoplay=1&modestbranding=1&autohide=1&showinfo=0&controls=0';
        $(".youtube").colorbox(cboxOptions);
    });

    $(window).resize(function () {
      $.colorbox.resize({
        width: window.innerWidth > parseInt(cboxOptions.maxWidth) ? cboxOptions.maxWidth : cboxOptions.width,
        height: window.innerHeight > parseInt(cboxOptions.maxHeight) ? cboxOptions.maxHeight : cboxOptions.height
      });
    });

    $(document).on('cbox_open',function () {
      $(document.body).css('overflow','hidden');
    }).on('cbox_closed',function () {
      $(document.body).css('overflow','');
    });

  });
})(jQuery);
